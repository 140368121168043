import React, { useState, useEffect } from "react";
import { Dropdown, Text, Switch } from "@nextui-org/react";
import "./Navbar.css";
import logo from "../../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import switchOnImage from "../../../assets/images/usa1.png";
import switchOffImage from "../../../assets/images/colombia1.png";
import menu from "../../../assets/images/menu.png";

import ajustes from "../../../assets/images/Ajustes.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [t, i18n] = useTranslation("global");
  const [user, setUser] = useState("Guest");
  const [redirectURL, setredirectURL] = useState(
    "https://firmadocdashboard.login.portal-id.com/"
  );
  const location = useLocation();
  const [isEnglish, setIsEnglish] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const userParam = query.get("user") ?? "Admin";
    const redirectURLParam =
      query.get("redirect") ?? "https://firmadocdashboard.login.portal-id.com/";

    i18n.changeLanguage('en');

    setUser(userParam);
    setredirectURL(redirectURLParam);
    clickOutside();
    


  }, [location]);


  const clickOutside = () =>{
    const handleClickOutside = (event) => {
      const menu = document.getElementById('menu-listado');
      const menuContainer = document.getElementById('menu-container');

      if (!menuContainer.contains(event.target)) {
        console.log("afuera");
        menu.classList.remove('menuOpen');

      }
    };
    document.addEventListener('click', handleClickOutside);
  }

  const handleLanguageToggle = () => {
    setIsEnglish(!isEnglish);
    if (isEnglish) {
      // Cambiar al idioma "en"
      i18n.changeLanguage("en");
    } else {
      // Cambiar al idioma "es"
      i18n.changeLanguage("es");
    }
  };
  function handleMenu() {
    console.log("hola");
    const navbar = document.getElementById("menu-listado");
    navbar.classList.add("menuOpen");
  }

  const MenuList = () => {
    return (
      <Dropdown>
        <Dropdown.Button light>
          <img
            src={ajustes}
            style={{ width: "24px", height: "24px", marginTop: "0%" }}
          />
          <Text
            size={16}
            css={{
              fontFamily: "Poppins-ExtraLight",
              paddingLeft: "3%",
              paddingTop: "1%",
            }}
          >
            {user}
          </Text>
        </Dropdown.Button>
        <Dropdown.Menu aria-label="Static Actions">
          {/* <Dropdown.Item key="new"> <Link to="https://firmadocdashboard.login.portal-id.com/perfil/" className="links" >{t("navbar.Perfil")}</Link></Dropdown.Item> */}
          <Dropdown.Item key="new">
            {" "}
            <Link to={redirectURL + "perfil/"} className="links">
              {t("navbar.Perfil")}
            </Link>
          </Dropdown.Item>
          {/* <Dropdown.Item key="copy"><Link to="https://firmadocdashboard.login.portal-id.com/firma_propia/" className="links" >{t("navbar.SubirGrafo")}</Link></Dropdown.Item> */}
          <Dropdown.Item key="copy">
            <Link to={redirectURL + "firma_propia/"} className="links">
              {t("navbar.SubirGrafo")}
            </Link>
          </Dropdown.Item>

          <Dropdown.Item key="edit">
            {t("navbar.GestionDocumentos")}
          </Dropdown.Item>
          <Dropdown.Item key="edit2">
            {t("navbar.GestionUsuarios")}
          </Dropdown.Item>
          <Dropdown.Item key="exit">{t("navbar.Salir")}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className="menu-container" id="menu-container">
      <div className="logo">
        <a href="#">
          <img src={logo} alt="alt" className="logo" />
        </a>
      </div>

      <div className="container-nav">
        <ul id="menu-listado" className="nav">
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_HOME}>{t("navbar.home")}</a> */}
            <a href={redirectURL + "dashboard/"}>{t("navbar.home")}</a>
          </li>
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_DOCUMENT}>{t("navbar.documents")}</a> */}
            <a href={redirectURL + "administrar/"}>{t("navbar.documents")}</a>
          </li>
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_TEMPLATE}>{t("navbar.templates")}</a> */}
            <a href={redirectURL + "plantillas/"}>{t("navbar.templates")}</a>
          </li>
          <li>
            {/* <a href={process.env.REACT_APP_NAVBAR_URL_REPORT}>{t("navbar.reports")}</a> */}
            <a href={"#"}>{t("navbar.reports")}</a>
          </li>
        </ul>
      </div>

      <div className="user-container">
        <ul className="user">
          <li>
            <MenuList style={{ marginLeft: "1%" }} />
            <img
              src={isEnglish ? switchOffImage : switchOnImage}
              alt="Switch de idioma"
              onClick={handleLanguageToggle}
              style={{ cursor: "pointer", height: "24px" }}
            />
          </li>
          <li>
            <img
              src={menu}
              alt="img"
              onClick={handleMenu}
              style={{ cursor: "pointer", height: "40px" }}
              className="menuIcon"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
